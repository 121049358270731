export const Gender = {
  GENDER_UNLIMITED: '所有性别',
  GENDER_MALE: '男',
  GENDER_FEMALE: '女',
};
export const defaultRules = {
  bidMin: 0.01,
  bidMax: 1000.0,
  bidScale: 2,
  campaignBudgetMin: 0.01,
  campaignBudgetMax: 1000000.0,
  adsetBudgetMin: 0.01,
  adsetBudgetMax: 1000000.0,
  budgetScale: 2,
};
