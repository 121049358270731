<template>
  <div class="platformSelect">
    <el-card header="投放平台">
      <div class="dp-flex">
        <div
          v-for="platformItem in platforms"
          class="platform dp-flex align-item-c justify-c mr-20"
          :class="{ selected: platform === platformItem.label }"
          :key="platformItem.label"
          @click="changePlatform(platformItem.label)"
        >
          {{ platformItem.label }}
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  props: {
    platform: {
      type: String,
    },
  },
  data() {
    return {
      platforms: [
        {
          label: 'facebook',
        },
        // {
        //   label: 'tiktok',
        // },
        // {
        //   label: 'google',
        // },
        // {
        //   label: 'line',
        // },
      ],
    };
  },
  methods: {
    // 选择平台
    changePlatform(key) {
      this.$emit('change', key);
    },
  },
};
</script>
<style lang="scss" scoped>
.platformSelect {
  .platform {
    width: 100px;
    height: 60px;
    border: 1px solid #eee;
    border-radius: 5px;
    background: #eee;
    &.selected {
      border: 1px solid #409eff;
      background: #409eff;
      color: #ffffff;
    }
  }
}
</style>
