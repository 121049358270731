import request from '@/utils/request';
import { getToken, getUserId } from '@/utils/token';

const gatewayUrl = process.env.VUE_APP_GATEWAY;
const fastdelivery = process.env.VUE_APP_FASTDELIVERY;

// 获取剪辑数据
export function getData(data) {
  return request({
    baseURL: fastdelivery,
    url: '/material/mixCut/getTaskInfo',
    method: 'post',
    params: {
      _token: getToken(),
    },
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    notThirdLoginUserId: true,
    notNeedToken: true,
  });
}
// 行为兴趣
export function targetingSearch(params) {
  return request({
    baseURL: gatewayUrl,
    url: '/gads2/delivery/targetingItems',
    params: {
      ...params,
      _token: getToken(),
      _user: getUserId(),
    },
    headers: {
      token: getToken(),
    },
  });
}

// 推荐广告语
export function chatGPTSloganRecommend(data) {
  return request({
    baseURL: gatewayUrl,
    url: '/fastDelivery/slogan/chatGPTSloganRecommend',
    method: 'post',
    params: {
      _token: getToken(),
    },
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    headers: {
      token: getToken(),
    },
  });
}

// 翻译哦
export function chatGtpSloganTranslate(data) {
  return request({
    baseURL: gatewayUrl,
    url: '/fastDelivery/slogan/chatGtpSloganTranslate',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    headers: {
      token: getToken(),
    },
  });
}
// 广告语优化
export function chatGtpSloganOptimization(data) {
  return request({
    baseURL: gatewayUrl,
    url: '/fastDelivery/slogan/chatGtpSloganOptimization',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    headers: {
      token: getToken(),
    },
  });
}

// 个号列表
export function myLoginList(params) {
  return request({
    baseURL: gatewayUrl,
    url: '/gads2/delivery/auto/myLoginList',
    params,
    headers: {
      token: getToken(),
    },
  });
}

// 广告账户列表
export function accountList(loginId) {
  return request({
    baseURL: gatewayUrl,
    url: `/gads2/delivery/account/accounts/${loginId}`,
    headers: {
      token: getToken(),
    },
  });
}

// 主页查询
export function accountPageList(params, thirdLoginUserId) {
  return request({
    baseURL: gatewayUrl,
    url: '/gads2/delivery/ads/accountPages',
    params,
    headers: {
      token: getToken(),
      thirdLoginUserId,
    },
  });
}

// 像素列表
export function AccountPixelList(params, thirdLoginUserId) {
  return request({
    baseURL: gatewayUrl,
    url: '/gads2/delivery/ads/accountPixel',
    params,
    headers: {
      thirdLoginUserId,
      token: getToken(),
    },
  });
}

//像素事件
export function pixelStatsList(params, thirdLoginUserId) {
  return request({
    baseURL: gatewayUrl,
    url: '/gads2/delivery/ads/pixelStats',
    params,
    headers: {
      token: getToken(),
      thirdLoginUserId,
    },
  });
}

// 站点查询
export function queryDomainByConditionList(data) {
  return request({
    baseURL: gatewayUrl,
    url: '/fastDelivery/asset/queryDomainByCondition',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    headers: { token: getToken() },
  });
}

// 站点分类
export function getSiteCategoryList(params) {
  return request({
    baseURL: gatewayUrl,
    url: '/fastDelivery/ads/getSiteCategory',
    params,
    headers: { token: getToken() },
  });
}

// 投放
export function autoVideoAd(data) {
  return request({
    baseURL: gatewayUrl,
    url: '/fastDelivery/auto/autoVideoAd',
    data,
    timeout: 60000,
    method: 'post',
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    headers: { token: getToken() },
  });
}

// 推广链接检测
export function websiteUrlCheck(params, thirdLoginUserId) {
  return request({
    baseURL: gatewayUrl,
    url: '/gads2/delivery/ads/siteCheck',
    params,
    headers: {
      token: getToken(),
      thirdLoginUserId,
    },
  });
}

// 根据商品id 获取商品信息
export function promoteableSale(params) {
  return request({
    baseURL: gatewayUrl,
    url: '/gads2/delivery/sale/promoteableSale',
    method: 'get',
    params: {
      ...params,
    },
  });
}
